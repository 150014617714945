<template>
  <div class="requests-page-wrapper" :ket="dynamicKey">
    <h1 class="requests-header">{{ $t('requests') }}</h1>
    <div class="requests-filter-and-create">
      <div class="requests-filter-section">
        <div class="search">
          <md-field>
            <img src="../../assets/images/search.svg" alt="search" class="search-icon">
            <md-input v-model="searchData" placeholder="Extended search" @input="filter" title="The search is processed on the following fields: TKM reference, Client reference, Client, Operative comments, Defendant, Notes (damage), Notes (case)"/>
          </md-field>
        </div>
        <div>
          <md-field>
            <md-select v-model="selectedStatus"
                       name="movie" id="movie"
                       multiple :placeholder="$t('status')"
                       @click="removeStyles"
                       @md-selected="filter">
              <md-option
                  v-for="(status,i) in statusesList"
                  :key="i"
                  :value="status"
                  :selected="selectedStatus === status"
              >
                {{ status }}
              </md-option>
            </md-select>
          </md-field>
        </div>
      </div>
      <div class="crate-wrapper">
        <router-link to="/requests/create" v-if="hasPermission('requests_create')">
          <button class="btn-create">
            <p>{{ $t('create') }}</p>
            <img src="../../assets/images/Path.png" alt="arrow">
          </button>
        </router-link>
      </div>
    </div>
    <div class="table-wrapper" v-if="admin">

      <md-table>
        <md-table-row class="head_row">
          <md-table-head
              v-for="(item, i) in filterHeadData"
              :key="i"
          >
            <md-ripple class="md-table-title">
              <div @click="customSorting(item.name, initialSort, i)">

                {{ item.title }}
                <img class="md-size-2x arrow_down" :class="`arrow_down_${i}`" src="../../assets/images/arrow_down.svg"/>
              </div>
            </md-ripple>
          </md-table-head>
          <md-table-head class="actions">{{ $t('actions') }}</md-table-head>
        </md-table-row>
        <md-table-row v-for="item in requests" :key="item.id"
                      @click="$router.push({name: 'requests-form-edit' , params: {id :item.id}})">
          <md-table-cell>{{ item.title }}</md-table-cell>
          <md-table-cell>{{ item.client_name }}</md-table-cell>
          <md-table-cell>{{ item.user_name }}</md-table-cell>
          <md-table-cell><span :class="item.status === 'New' ? 'red-font' : '' || item.status === 'Waiting for answer' ? 'orange-font': '' " >{{ $t(item.status) }}</span></md-table-cell>
          <md-table-cell><span :class="item.priority === 'Urgent' ? 'back-red': ''">{{ $t(item.priority) }}</span></md-table-cell>
          <md-table-cell>{{ $t(item.nature) }}</md-table-cell>
          <md-table-cell>{{ item.request_date }}</md-table-cell>
          <md-table-cell
              v-if="hasPermission('requests_update') || hasPermission('requests_delete')">
            <div v-if="item.status !== 'Accepted'" class="resuest_btn">
              <router-link
                  :to="{ name: 'requests-form-edit', params: { id: item.id }}"
                  class="actions-section"
              >
                <md-button class="md-dense md-raised" v-if="hasPermission('requests_update')">
                  <md-icon>edit</md-icon>
                </md-button>
              </router-link>
              <br>
              <md-button class="md-raised md-accent" @click.stop="handlerDelete(item.id)"
                         v-if="hasPermission('requests_delete')">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
            <div v-else class="read_only">
              <span class="material-icons">
               visibility_off
            </span>
            </div>

          </md-table-cell>
        </md-table-row>
      </md-table>
      <custom-pagination
          :list="requests"
          :page="pagination.current_page"
          :pagesLength="pagesLength"
          :changePage="changePage"
      />
    </div>

    <div class="table-wrapper" v-else>

      <md-table>
        <md-table-row class="head_row">
          <md-table-head
              v-for="(item, i) in this.$t('requestHeadData')"
              :key="i"
          >
            <md-ripple class="md-table-title">
              <div @click="customSorting(item.name, initialSort, i)">

                {{ item.title }}
                <img class="md-size-2x arrow_down" :class="`arrow_down_${i}`" src="../../assets/images/arrow_down.svg"/>
              </div>
            </md-ripple>
          </md-table-head>
          <md-table-head class="actions">{{ $t('actions') }}</md-table-head>
        </md-table-row>

        <md-table-row v-for="item in requests" :key="item.id"
                      @click="$router.push({name: 'requests-form-edit' , params: {id :item.id}})">
          <md-table-cell>{{ item.title }}</md-table-cell>
          <md-table-cell>{{ item.client_name }}</md-table-cell>
          <md-table-cell>{{ item.user_name }}</md-table-cell>
          <md-table-cell><span :class="item.status === 'New' ? 'red-font': '' || item.status === 'Waiting for answer' ? 'orange-font': '' ">{{ $t(item.status) }}</span></md-table-cell>
          <md-table-cell><span :class="item.priority === 'Urgent' ? 'back-red': ''">{{ $t(item.priority) }}</span></md-table-cell>
          <md-table-cell>{{ $t(item.nature) }}</md-table-cell>
          <md-table-cell>{{ item.request_date }}</md-table-cell>
          <md-table-cell
              v-if="hasPermission('requests_update') || hasPermission('requests_delete')">
            <div v-if="item.status !== 'Accepted'" class="resuest_btn">
            <router-link
                :to="{ name: 'requests-form-edit', params: { id: item.id }}"
                class="actions-section"
            >
              <md-button class="md-dense md-raised" v-if="hasPermission('requests_update')">
                <md-icon>edit</md-icon>
              </md-button>
            </router-link>
            <br>
            <md-button class="md-raised md-accent" @click.stop="handlerDelete(item.id)"
                       v-if="hasPermission('requests_delete')">
              <md-icon>delete</md-icon>
            </md-button>
            </div>
            <div v-else class="read_only">
              <span class="material-icons">
               visibility_off
            </span>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <custom-pagination
          :list="requests"
          :page="pagination.current_page"
          :pagesLength="pagesLength"
          :changePage="changePage"
      />
    </div>
  </div>
</template>

<script>
import {userPermissions} from '@/mixins/userPermissions';
import {mapActions, mapGetters, mapMutations} from "vuex";
import CustomPagination from "../../components/pagination/CustomPagination";

export default {
  name: "requests-list",
  components: {CustomPagination},
  data: () => ({
    dynamicKey: 0,
    title: '',
    selectedDate: null,
    selected: [],
    selectedStatus: [],
    admin: "",
    searchData: '',
    loggedManagerId: null,
    requests: [],
    permissions: [],
    pagination: {
      current_page: null,
      from: null,
      last_page: null,
      per_page: null,
      total: null
    },
    initialSort: "desc",
    sortName: '',
    sort: '',
  }),
  mixins: [
    userPermissions
  ],

  async mounted() {
    await this.getUserInfo()
    await this.getStatusesList()
    let users = JSON.parse(localStorage.getItem('user'))
    if (users.user.roles[0].name === "manager") {
      this.loggedManagerId = users.user.id
    }
    if (this.userData.roles[0].name === 'client') {
      await this.getPaginatedClientList({})
      this.filterStatusesList()
      this.admin = false
    } else {
      this.admin = true
      await this.getPaginatedList({})
    }

    await this.getUsersList()
  },

  watch: {
    userData: {
      deep: true,
      async handler () {
        this.getStatusesList()
        this.selectedStatus = []
        this.dynamicKey++
      }
    }
  },

  methods: {
    ...mapMutations(['filterStatusesList']),
    async customSorting(sortName, sort, i) {
      const status = this.selectedStatus.map(item => this.$t(item))
      if (this.initialSort === "desc") {
        document.querySelector(`.arrow_down_${i}`).style.transform = 'rotate(180deg)'
        this.initialSort = "asc"
      } else {
        document.querySelector(`.arrow_down_${i}`).style.transform = 'rotate(0deg)'
        this.initialSort = "desc"
      }
      this.$t('requestHeadData').map(item => {
        if (sort === 'desc' && item.name === sortName) {
          item.desc = false
          item.asc = true
        } else {
          item.asc = false
          item.desc = true
        }
      })

      this.sortName = sortName
      this.sort = sort
      if (this.userData.roles[0].name === 'client') {
        await this.getPaginatedClientList({
          page: this.pagination.current_page,
          title: this.searchData,
          description: this.searchData,
          answer_id: this.searchData,
          status,
          sort: sortName,
          sortBy: sort
        })
        this.admin = false

      } else {
        await this.getPaginatedList({
          page: this.pagination.current_page,
          title: this.searchData,
          description: this.searchData,
          answer_id: this.searchData,
          status,
          sort: sortName,
          sortBy: sort,
          managerId: this.loggedManagerId,
        })

      }
    },
    removeStyles () {
        const customStyle = document.getElementById('customStyle');
        customStyle.innerHTML = ''
    },
    async getPaginatedList({page = 1, title = '', description = '', answer_id = '', status = [], sort = '', sortBy = 'desc', managerId = this.loggedManagerId}) {
      await this.getRequestsList({page, title, description, answer_id,  status, sort, sortBy, managerId})
          .then(data => {
            this.requests = data
            this.pagination.current_page = this.reqPagination.current_page
            this.pagination.from = this.reqPagination.from
            this.pagination.last_page = this.reqPagination.last_page
            this.pagination.per_page = this.reqPagination.per_page
            this.pagination.total = this.reqPagination.total
          })
    },
    async getPaginatedClientList({page = 1, title = '', status = [], sort = '', sortBy = 'desc'}) {
      await this.getClientRequests({page, title, status, sort, sortBy})
          .then(data => {
            this.requests = data
            this.pagination.current_page = this.reqPagination.current_page
            this.pagination.from = this.reqPagination.from
            this.pagination.last_page = this.reqPagination.last_page
            this.pagination.per_page = this.reqPagination.per_page
            this.pagination.total = this.reqPagination.total
          })
      this.admin = false
    },
    ...mapActions(['getRequestsList', 'getDeleteRequest', 'getUserInfo', 'getClientRequests', 'getStatusesList', 'getUsersList', 'getPermissionsList']),

    async changePage(currentPage) {
      const status = this.selectedStatus.map(item => this.$t(item))
      if (this.userData.roles[0].name === 'client') {
        await this.getPaginatedClientList({
          page: currentPage,
          title: this.searchData,
          description: this.searchData,
          answer_id: this.searchData,
          status,
          sort: this.sortName,
          sortBy: this.sort
        })
        this.admin = false
      } else {
        await this.getPaginatedList({
          page: currentPage,
          title: this.searchData,
          description: this.searchData,
          answer_id: this.searchData,
          status,
          sort: this.sortName,
          sortBy: this.sort,
          managerId: this.loggedManagerId,
        })
      }
    },

    async filter() {
      const status = this.selectedStatus.map(item => this.$t(item))
      if (this.userData.roles[0].name === 'client') {
        await this.getPaginatedClientList({
          page: 1,
          title: this.searchData,
          description: this.searchData,
          answer_id: this.searchData,
          status, sort: this.sortName, sortBy: this.sort
        })
        this.admin = false
      } else {

        await this.getPaginatedList({
          page: 1,
          title: this.searchData,
          description: this.searchData,
          answer_id: this.searchData,
          status,
          sort: this.sortName,
          sortBy: this.sort,
          managerId: this.loggedManagerId,
        })
      }
    },

    async axiosHandler(id) {
      const status = this.selectedStatus.map(item => this.$t(item))
      const deletedRequest = await this.getDeleteRequest(id)
      if (deletedRequest.status === 'success') {
        if (this.userData.roles[0].name === 'client') {
          this.requests = await this.getClientRequests({
            page: 1,
            title: this.searchData,
            description: this.searchData,
            answer_id: this.searchData,
            status,
            sort: this.sortName,
            sortBy: this.sort
          }, this.userData.client_id)
        } else {
          this.requests = await this.getRequestsList({
            page: 1,
            title: this.searchData,
            description: this.searchData,
            answer_id: this.searchData,
            status,
            sort: this.sortName,
            sortBy: this.sort,
            managerId: this.loggedManagerId,
          })
        }
      }
    },
    async handlerDelete(id = null) {
      let conf = confirm("Are you sure?");

      if (!conf) {
        return;
      }

      if (id != null) {
        await this.axiosHandler(id)

      } else {
        let ids = this.selected;
        let actions = [];

        ids.map(id => {
          actions.push(
              this.axiosHandler(id)
          )
        });
        try {
          await Promise.all(actions)

        } catch (e) {
          console.log(e)
        }
      }

    },

    hasPermission(name) {
      return this.userPermissions.indexOf(name) >= 0
    },

  },

  computed: {
    ...mapGetters(['requestsList', 'userData', 'statusesList', 'reqPagination', 'userData']),
    pagesLength() {
      return Math.ceil(+this.pagination.total / +this.pagination.per_page)
    },
    filterHeadData() {
      if (this.userData.roles[0].name === 'client') {
         return this.$t('requestHeadData').filter(data => data.title !== 'Company')
      }
      return this.$t('requestHeadData')
    }
  },
}
</script>

<style lang="scss">
.red-font {
  color: red;
}
.orange-font {
    color: #EC8B31;
}

.back-red {
  background: red;
  color: white;
}
.md-checkbox .md-checkbox-container {
  width: 20px;
  min-width: 20px;
  height: 20px;
  position: relative;
  transition: .4s cubic-bezier(.25, .8, .25, 1);
  border: 1px solid #CDD0D9 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
}

.md-table-head-label {
  color: #000000 !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.md-table-title > div {
  color: #000000 !important;
  font-weight: bold;
}

.arrow_down:hover {
  cursor: pointer;
}

.arrow_down {
  height: 18px !important;
  overflow: auto;
  transition: 0.25s ease;
}

.head_row {
  border-bottom: 1.5px solid #000000 !important;
}

.md-checkbox.md-theme-default.md-checked.md-primary .md-checkbox-container {
  background-color: #054F63 !important;
}

.md-checkbox.md-theme-default.md-checked .md-checkbox-container {

  background-color: #054F63 !important;
}

.requests-page-wrapper {
  padding: 0 20px;

  .requests-header {
    font-weight: 900;
    font-size: 36px;
    line-height: 52px;
    color: #000;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 16px;
  }

  .requests-filter-and-create {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .requests-filter-section {
    display: flex;
    align-items: center;
  }

  .start-date {
    margin-left: 20px !important;
  }

  .md-card-content {
    width: 260px;
    margin-left: 20px;
    padding: 0 !important;
  }

  .md-table-row:hover {
    cursor: pointer;
  }

  .table-wrapper {
    padding-right: 20px;
  }

  .md-table-cell:last-child .md-table-cell-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .md-table-cell-container {
    padding: 6px 25px 6px 21px !important;
  }

  .search-selects {
    position: absolute;
    left: 15px;
  }

  .search {
    display: flex;

    &-icon {
      margin-left: 20px;
      width: 20px !important;
    }
  }

  .filter-status-wrap {
    margin-left: 15px;

    select {
      width: 250px;
    }
  }

  .select-field {
    background: #FFFFFF;
    border-radius: 4px;
    height: 48px;
    padding: 0 0 0 5px;
    margin: 0;
    width: 250px;
  }

  .md-button {
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 6px;
    width: 30px !important;
    height: 30px;
    min-width: unset;
  }


  .line {
    margin: 0 10px;
  }

  .crate-wrapper {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .md-field {
    width: 260px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0 20px 0 0;
    padding-top: 0;

    &::after {
      background-color: unset !important;
      position: unset !important;
    }

    .md-input {
      height: unset !important;
      width: 100%;
      padding-left: 10px;
    }
  }

  .btn-create {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 146px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    border-radius: 6px;
    margin-left: 10px;
    cursor: pointer;

  }

  .btn-create:hover {
    background: #F7F7F8;
  }

  .btn-create p {
    margin: 0;
    padding: 0 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;

  }


  .date-picker {
    width: 285px;
    height: 48px;
    border: 1px solid #CDD0D9 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
  }

  .md-table-head:last-child:not(:first-child) .md-table-head-label {
    justify-content: center !important;
  }

  .actions-section {
    .md-table-cell-container {
      display: flex !important;
      justify-content: center !important;
    }
  }

  .md-field > .md-icon {
    margin: auto !important;
  }

  .md-button {
    min-width: unset !important;
  }

  .md-table.md-theme-default .md-table-content, .md-table.md-theme-default .md-table-alternate-header {
    border: none !important;
    height: unset !important;
    max-height: unset !important;
  }

  .md-card {
    box-shadow: unset !important;
  }

  .md-button.md-theme-default.md-raised:not([disabled]).md-accent {
    background-color: unset !important;
  }

  .md-button.md-theme-default.md-raised:not([disabled]).md-accent .md-icon-font {
    color: rgba(0, 0, 0, 0.54) !important;
  }

  .md-checkbox.md-theme-default .md-checkbox-container {
    border: 1px solid #CDD0D9 !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
  }

  .requests-page-wrapper .md-button {
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 6px;
    width: 36px !important;
    height: 36px;
    min-width: unset;
  }

  .requests-page-wrapper .md-table-sortable-icon svg {
    fill: #EC8B31 !important;
    height: 18px !important;
  }

  .requests-page-wrapper .md-ripple, .requests-page-wrapper .md-table-cell-container {
    font-weight: normal;
    font-size: 15px;
    line-height: 32px;
    color: #000000;
    text-align: left;
  }

  .md-table-sortable-icon {
    opacity: 1 !important;

    svg {
      fill: #EC8B31 !important;
      height: 18px !important;
    }
  }

  .md-table.md-theme-default .md-table-content, .md-table.md-theme-default .md-table-alternate-header {
    height: unset !important;
    max-height: unset !important;
  }

  .md-table {
    margin-top: 30px;
    box-shadow: unset !important;
  }

  .md-table-fixed-header-container > table {
    width: 100% !important;
  }

  .md-table + .md-table {
    margin-top: 16px
  }

  .md-ripple, .md-table-cell-container {
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
    text-align: left;
  }

  .md-table-fixed-header-container > table > thead > tr {
    border-bottom: 1px solid #000000;
  }

  .md-checkbox .md-checkbox-container {
    border: 1px solid #CDD0D9 !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
  }

  .md-table-cell-selection .md-checkbox {
    margin: 0 47px 0 37px;
  }

  .md-table-cell-selection .md-checkbox .md-checkbox-container:after {
    top: 0 !important;
    left: 5px !important;
  }

  .md-table-cell {
    &:last-child .md-table-cell-container {
      display: flex;
    }
  }

  //.md-table-head-label {
  //  font-weight: bold;
  //  font-size: 16px;
  //  padding-right: unset !important;
  //  padding-left: 18px !important;
  //  width: 100% !important;
  //  height: 100% !important;
  //}

  .md-table-head-container {
    padding-top: 10px !important;
    padding: unset;
  }
  .resuest_btn {
    display: flex;
  }
  .read_only {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

}
</style>
<style lang="scss" >
.requests-page-wrapper {
  .md-table-head-label {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    padding-right: unset !important;
    padding-left: 22px !important;
    width: 100% !important;
    height: 100% !important;
  }
}
</style>
